import React, { useState, useEffect } from "react";
import { useNavigate , Link } from "react-router-dom";
import Layout from "../components/layout/Layout";
import axios from "axios";
import { Checkbox, Radio } from "antd";
import { Prices } from "../components/Prices";
import { useCart } from "../context/Cart";
import toast from "react-hot-toast";
import "../styles/Homepage.css";
import { AiOutlineReload } from "react-icons/ai";
import bannerImage from "../pages/images/banner.png";
import UpperImage from "../../src/pages/images/upper-image.jpg";
import ImageEasy from "../../src/pages/images/image-easy.png";
import Logo1 from "../../src/pages/images/logo11.png";
import Logo2 from "../../src/pages/images/logo-12.png";
import Logo3 from "../../src/pages/images/logo13.png";
import Logo4 from "../../src/pages/images/logo14.png";
import Logo5 from "../../src/pages/images/logo15.png";
import SocialBanner from "../../src/pages/images/social_banner.png";
import PhotoGal1 from "../../src/pages/images/PhotoGal1.png";
import PhotoGal2 from "../../src/pages/images/PhotoGal2.png";
import Quality from "../../src/pages/images/quality.png";
import Cor1 from "../../src/pages/images/cor1.png";
import Cor2 from "../../src/pages/images/cor2.png";
import Cor3 from "../../src/pages/images/cor3.png";
import varsity from "../../src/pages/images/varsity-jacket .png";
// import Review1 from "../../src/pages/images/review2.png";
// import Review2 from "../../src/pages/images/review3.png";
// import Review3 from "../../src/pages/images/review4.png";
import {Rating} from 'react-simple-star-rating'
import Re1 from "../../src/pages/images/re1.png";
import Re2 from "../../src/pages/images/re2.png";
import Re3 from "../../src/pages/images/re3.png";
import SideImage from "../../src/pages/images/side-image.png";
import CartImage from "../../src/pages/images/cart-fill.svg";
import BombJack from "../../src/pages/images/bombjack.png";
import VarsJack from "../../src/pages/images/varsjack.png";
import NorthHood from "../../src/pages/images/northhood.png";
import ReadyToShip from "../../src/pages/images/readytoship.png";
import { baseURL, Custom_jacket } from "../constants/url";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const HomePage = () => {
  const navigate = useNavigate();
  const [cart, setCart] = useCart();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [checked, setChecked] = useState([]);
  const [radio, setRadio] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [showInfo, setShowInfo] = useState(false);

  //get all cat
  const getAllCategory = async () => {
    try {
      const { data } = await axios.get("/api/v1/category/get-category");
      if (data?.success) {
        setCategories(data?.category);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCategory();
    getTotal();
  }, []);
  //get products
  const getAllProducts = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}?isActive=true`);
      console.log(data, "product");
      setLoading(false);
      setProducts(data.products);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  //getTOtal COunt
  const getTotal = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/product-count");
      setTotal(data?.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (page === 1) return;
    loadMore();
  }, [page]);
  //load more
  const loadMore = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`/api/v1/product/product-list/${page}`);
      console.log(data, "get all products");
      setLoading(false);
      setProducts([...products, ...data?.products]);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // filter by cat
  const handleFilter = (value, id) => {
    let all = [...checked];
    if (value) {
      all.push(id);
    } else {
      all = all.filter((c) => c !== id);
    }
    setChecked(all);
  };
  useEffect(() => {
    getAllProducts();
  }, []);


  
  //get filterd product
  const filterProduct = async () => {
    try {
      const { data } = await axios.post("/api/v1/product/product-filters", {
        checked,
        radio,
      });
      setProducts(data?.products);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Layout title={"All Products - Best offers "}>
   <Carousel autoPlay={true} infiniteLoop={true} interval={3000} showStatus={false} showThumbs={false}>
    <div>
      <img className="d-block w-100" src="./slider1.jpeg" alt="First slide" />
    </div>
    <div >
      <img className="d-block w-100" src="./slider2.jpeg" alt="Second slide" />
    </div>
    <div >
      <img className="d-block w-100" src="./slider3.jpeg" alt="Third slide" />
    </div>
    </Carousel>
 
      <div
        className="container-fluid d-flex flex-column justify-content-center align-items-center min-vh-100 home-page"
        style={{ overflowX: "hidden" }}
      >     
      {/* section 1/2 */}
     <div className="d-flex flex-lg-row flex-column justify-content-center w-100 align-items-center container-fluid" style = {{ marginBottom : "3rem"}}>
       <div className="text-center border inline-block w-100" style= {{ flexBasis : '20%' , padding :'20px'}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
</svg>
            <h6>Customize</h6>
            <p>Fully Customise the jacket</p>
     </div>
     <div className="text-center border inline-block w-100" style= {{ flexBasis : '20%' , padding :'20px' , backgroundColor : 'black' , color : 'white'}}>
     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-stack" viewBox="0 0 16 16">
  <path d="m14.12 10.163 1.715.858c.22.11.22.424 0 .534L8.267 15.34a.6.6 0 0 1-.534 0L.165 11.555a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0l5.317-2.66zM7.733.063a.6.6 0 0 1 .534 0l7.568 3.784a.3.3 0 0 1 0 .535L8.267 8.165a.6.6 0 0 1-.534 0L.165 4.382a.299.299 0 0 1 0-.535z"/>
  <path d="m14.12 6.576 1.715.858c.22.11.22.424 0 .534l-7.568 3.784a.6.6 0 0 1-.534 0L.165 7.968a.299.299 0 0 1 0-.534l1.716-.858 5.317 2.659c.505.252 1.1.252 1.604 0z"/>
</svg>
             <h6>Bulk Orders</h6>
              <p>We also cater Bulk orders</p>
     </div>
     <div className="text-center border inline-block w-100" style= {{ flexBasis : '20%' , padding :'20px'}}>
     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock-history" viewBox="0 0 16 16">
  <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
  <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
  <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
</svg>
     <h6>Just arrived</h6>
     <p>Check out latest item</p>
     </div>
   </div>
       {/* section3 */}
        <div className="container-fluid">
          <div className="d-flex flex-lg-row flex-column align-items-center ">
            <div className="col-12 col-md-6 d-flex justify-content-center text-center">
              <div className="image-container position-relative">
                <img
                  src={varsity}
                  alt="banner image"
                  className="main-image img-fluid"
                  style={{ maxWidth: "80%" }} // Adjust the max-width as needed
                />
                <img
                  src={CartImage}
                  alt="small image"
                  className="small-image position-absolute"
                  style={{ top: "20px", right: "90px", width: "4%" }} // Adjust width and positioning as needed
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                />
                {showInfo && (
                  <div
                    className="info-box position-absolute"
                    style={{ top: "50px", right: "20px" }}
                  >
                    {" "}
                    {/* Adjust positioning as needed */}
                    <p
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      BLACK VARSITY JACKET
                    </p>
                    <ul>
                      <p>Varsity Jackets |</p>
                      <p>Wool & Leather Varsity Jackets</p>
                      <p
                        style={{
                          marginBottom: 0,
                          fontWeight: "700",
                          fontSize: "larger",
                        }}
                      >
                        From $80.00
                      </p>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-0 text-center">
              <p className="text-uppercase">Classic Style Meets Durability</p>
              <h1 style ={{fontSize :'36px'}}>
                The Wool and Leather Varsity Jacket
              </h1>
              <p className="text-center mt-4">
                Embrace timeless elegance and rugged quality with our Wool and
                Leather varsity jacket. Crafted from premium materials, this
                jacket combines the warmth of wool with the durability of
                leather. Stay stylish in any season, whether you're heading to
                the game or out for a night on the town. A perfect blend of
                comfort, style, and versatility, this jacket is a must-have for
                fashion-forward individuals.
              </p>
            </div>
          </div>
        </div>
        {/*section4*/}
        <div className="container-fluid">
          <div className="d-flex flex-lg-row flex-column-reverse align-items-center">
            <div className="col-12 col-md-6 text-center mb-4 mb-md-0">
              <p className="text-uppercase">Customized Varsity Jackets</p>
              <h1>Wear Your Style With Pride!</h1>
              <p className="mx-4 m-4">
                Design your own unique varsity jacket and stand out from the
                crowd. Our customization options allow you to showcase your
                personal style and make a statement. From choosing the colors
                and materials to adding patches, embroidery, and personalized
                lettering, we'll bring your vision to life. Whether you're
                representing your school, team, or personal brand, our custom
                varsity jackets are the perfect canvas for self-expression. Wear
                your style with pride and create a jacket that's truly
                one-of-a-kind. Start designing your custom varsity jacket today!
              </p>
              <button
                className="btn btn-outline-dark mt-3"
                style={{ fontWeight: 700 }}
                onClick={() => navigate(`/AllProducts`)}
              >
                Customize Your Jacket
              </button>
            </div>
            <div className="col-12 col-md-6 d-flex justify-content-center">
              <img
                style={{
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  margin: "10px",
                  border: "1px solid #ddd",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  maxWidth: "100%", // Make sure image scales with its container
                }}
                src={ReadyToShip}
                className="img-fluid"
                alt="banner image"
              />
            </div>
          </div>
        </div>
          {/* section4 */}
        <div
          className="parent-container"
          style={{
            width: "100%",
          }}
        >
          <div
            className="banner-container d-flex justify-content-center align-items-center"
            style={{
              width: "70%",
              paddingLeft: "30%",
              alignItems: "center",
            }}
          >
            <img
              style={{
                cursor: "pointer",
                transition: "transform 0.3s ease",
                margin: "10px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              src={PhotoGal1}
              className="banner-img img-fluid"
              alt="banner image"
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
              onClick={() => navigate(`/our-work`)}
            />
            <img
              style={{
                cursor: "pointer",
                transition: "transform 0.3s ease",
                margin: "10px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              src={PhotoGal2}
              className="banner-img img-fluid"
              alt="banner image"
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            />
          </div>
        </div>
        {/* section5 */}
        <div
          className="parent-container"
          style={{
            width: "100%",
          }}
        >
          <div
            className="banner-container d-flex justify-content-center align-items-center"
            style={{
              width: "100%",
            }}
          >
            <img
              style={{
                cursor: "pointer",
                transition: "transform 0.3s ease",
                margin: "10px",
                border: "1px solid #ddd",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
              src={Quality}
              className="banner-img img-fluid"
              alt="banner image"
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
              onClick={() => navigate(`/our-work`)}
            />
          </div>
        </div>
         
        <div className="w-100 product-styling" >
      {products?.map((p,key) =>(
        <Link key={key} to={ `/product/${p?._id}`} >
        <div className=" text-center " key={key} style={{ background : 'transparent' , width : '16rem'} }>
        <img src={`${baseURL}/api/v1/product/product-photo/${p?._id}/${p.photo.length -1} `} className="card-img-top" alt="Product Name" style={{ width :'16rem',  height :'20rem' , objectFit: 'contain' , margin : '0 auto'}}/>
        <div className="">
            <h5 className="card-title" style = {{ fontSize :'14px'}}>{p?.name}</h5>
            <p className="card-text">${p?.designId?.custom_price}</p>
        </div> 
        </div>
        </Link>
      ))}
    
    </div>

        
        {/* section 6 Blogs */}
        <div className="d-flex flex-column justify-content-center w-100 align-items-center container-fluid mt-4" style={{padding :'20px'}}>
           <h1>Latest News</h1>
           <div className="d-flex flex-lg-row flex-column justify-content-around  align-items-center container-fluid" style= {{ marginTop :'30px'}}>
           <div className="text-center my-4" style={{ flexBasis: '40%' }}> 
            <img src = "./biker.PNG" alt="biker" style = {{borderRadius :'25px' , height : '20rem'}}/>
            <h4 style={{textDecoration: 'bold' , color :'black', padding :'15px' }}>Fashion Trends for biker 2022</h4>
            <p>Biker jackets have long been a symbol of rugged style and rebellion, but in recent years, they’ve evolved into a fashion trend that transcends...........</p>
            <h5>Read More</h5>
           </div>
           <div className="text-center my-4" style={{ flexBasis: '40%' }}> 
            <img src = "./biker2.PNG" alt="biker" style = {{borderRadius :'25px' , height : '20rem'}}/>
            <h4 style={{textDecoration: 'bold' , color :'black', padding :'15px'}}>Hoodies or PullOver? The Better Choice</h4>
            <p>Biker jackets have long been a symbol of rugged style and rebellion, but in recent years, they’ve evolved into a fashion trend that transcends...........</p>
            <h5>Read More</h5>
           </div>
           </div>
        </div>
        {/* section 7 Testimonials */}
        <div className="d-flex flex-column justify-content-center w-100 align-items-center container-fluid mt-4" style={{padding :'20px'}}>
               <h1>What people say about us!</h1>
               <div className="d-flex flex-lg-row flex-column justify-content-around  align-items-center container-fluid" style= {{ marginTop :'30px'}}>
                    <div className="text-center my-4" style={{ flexBasis: '30%' }}>
                       <img src='./face1.PNG' alt="face" className="rounded-circle" style={{ width: '30%' , marginBottom: '20px'}}/>
                       <p>I recently purchased a jacket from EasyJacket, and I am beyond impressed! The quality of the material is top-notch, offering both warmth and breathability, perfect for chilly days. The fit is true to size, and the design is both stylish and versatile, making it easy to pair with any outfit.</p>
                       <Rating  initialValue={5}/>
                    </div>
                    <div  className="text-center my-4" style={{ flexBasis: '30%' }}>
                    <img src='./face2.PNG' alt="face" className="rounded-circle" style={{ width: '30%' , marginBottom: '20px' }}/>
                    <p>I recently purchased a jacket from EasyJacket, and I am beyond impressed! The quality of the material is top-notch, offering both warmth and breathability, perfect for chilly days. The fit is true to size, and the design is both stylish and versatile, making it easy to pair with any outfit.</p>
                     <Rating initialValue={4}/>
                    </div>
                    <div  className="text-center my-4" style={{ flexBasis: '30%' }}>
                    <img src='./face3.PNG' alt="face" className="rounded-circle" style={{ width: '30%' , marginBottom: '20px'}}/>
                    <p>I recently purchased a jacket from EasyJacket, and I am beyond impressed! The quality of the material is top-notch, offering both warmth and breathability, perfect for chilly days. The fit is true to size, and the design is both stylish and versatile, making it easy to pair with any outfit.</p>
                    <Rating initialValue={5}/>
                    </div>
               </div>
        </div>


        {/* section 9 */}
        <div className="d-flex flex-column justify-content-center w-100 align-items-center container-fluid mt-4" style={{backgroundColor : '#FDF5E2' , padding :'20px'}}>
             <h3>CLEARANCE SALE</h3>
             <p>Up TO 50% Off. All Sales are Final!</p>
             <p>Last chance to take advantage of our discount!</p>
             <button style={{width: '8rem' , padding: '5px' , background : 'black' , color:'white'} }>Shop now</button>
        </div>
        {/* <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mb-5">
          <div className="carousel-container ms-md-4">
            <div
              id="carouselExampleIndicators"
              className="carousel slide carousel-fade"
              data-bs-ride="carousel"
              data-bs-interval="3000"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  className="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="overlay-container">
                    <img
                      src={Cor1}
                      className="d-block w-100 fixed-size"
                      alt="Review 1"
                    />
                    <div className="overlay"></div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="overlay-container">
                    <img
                      src={Cor2}
                      className="d-block w-100 fixed-size"
                      alt="Review 2"
                    />
                    <div className="overlay"></div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="overlay-container">
                    <img
                      src={Cor3}
                      className="d-block w-100 fixed-size"
                      alt="Review 3"
                    />
                    <div className="overlay"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="container-fluid">
          <div
            className="row justify-content-center align-items-center text-center"
            style={{ marginTop: "10%" }}
          >
            <div className="col-12">
              <p>RUNNING WORLD</p>
              <h1>EXPLORE THE RANGE</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-4 mb-4">
              <div className="image-container position-relative">
                <img
                  src={BombJack}
                  alt="Bomber Jacket"
                  className="img-fluid w-100"
                />
                <div
                  className="hover-box"
                  onClick={() => navigate(`/category/bomber-jackets`)}
                >
                  EXPLORE COLLECTION
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className="image-container position-relative">
                <img
                  src={VarsJack}
                  alt="Varsity Jacket"
                  className="img-fluid w-100"
                />
                <div
                  className="hover-box"
                  onClick={() => navigate(`/category/varsity-jackets`)}
                >
                  EXPLORE COLLECTION
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 mb-4">
              <div className="image-container position-relative">
                <img src={NorthHood} alt="Hoodie" className="img-fluid w-100" />
                <div
                  className="hover-box"
                  onClick={() => navigate(`/category/hoodies`)}
                >
                  EXPLORE COLLECTION
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Layout>
  );
};

export default HomePage;
