import React, { useState, useEffect } from "react";
import Layout from "../components/layout/Layout";
import { useCart } from "../context/Cart";
import { useAuth } from "../context/auth";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import DropIn from "braintree-web-drop-in-react";
import "../styles/CartStyles.css";

const CartPage = () => {
  const [auth, setAuth] = useAuth();
  const [cart, setCart] = useCart();
  const [cartData, setCartData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [clientToken, setClientToken] = useState("");
  const [instance, setInstance] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  //total price
  const totalPrice = () => {
    try {
      let total = 0;
      [...cart, ...cartData]?.map((item) => {
        console.log(item.price)
        console.log(item.custom_price)
        total = total + item.designId.custom_price;
      });
      return total.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCarts = async () => {
    try {
      const index = searchParams.get("index").split(",");
      const { data } = await axios.post("/api/v1/custom/getAllCart", {
        carts: [...index],
      });
      console.log(data.data);
      setCartData(data.data);
    } catch (error) {
      console.log(error);
    }
  };
  //detele item
  const removeCartItem = (pid) => {
    try {
      let myCart = [...cart];
      let index = myCart.findIndex((item) => item._id === pid);
      myCart.splice(index, 1);
      setCart(myCart);
      sessionStorage.setItem("cart", JSON.stringify(myCart));
    } catch (error) {
      console.log(error);
    }
  };

  const getToken = async () => {
    try {
      const { data } = await axios.get("/api/v1/product/braintree/token");
      setClientToken(data?.clientToken);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getToken();
  }, [auth?.token]);

  //handle payments
  const handlePayment = async () => {
    try {
      setLoading(true);
      const { nonce } = await instance.requestPaymentMethod();
      const { data } = await axios.post("/api/v1/product/braintree/payment", {
        nonce,
        cart,
      });
      setLoading(false);
      sessionStorage.removeItem("cart");
      setCart([]);
      navigate("/dashboard/user/orders");
      toast.success("Payment Completed Successfully ");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCarts();
  }, []);

  return (
    <Layout>
      <div className=" container-fluid" style = {{marginTop : '2rem'}}>
        <div className=" d-flex justify-content-around flex-md-row flex-column-reverse" style = {{ flexBasis : "30%" , gap : "15%" }}>
          <div className="w-100">
        <div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={ { background : 'black' , color : 'white' , borderColor : 'transaparent'}}>
         All Products
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div className="accordion-body" style={{ overflowY : "scroll"}}>
      {[...cart, ...cartData]?.map((p) => (
                <div className="card d-flex flex-md-row flex-column p-4 justify-content-between align-items-center gap-2" key={p._id} >
                  <div>
                    <Link to={`/Design/${p.designId._id}`}>
                    <img
                      src={`${p.designId.custom_image}`}
                      className="card-img-top"
                      alt={p.name}
                      style={{ width : "100px" , objectFit : 'contain'}}
                    />
                    </Link>
                  </div>
                  <div className="text-center" style={{ width :"100%"}}>
                    <p style = {{ fontSize : "14px" , fontWeight : "700" , textTransform: "uppercase"}}>{p.designId.globals.catName}</p>
                  <p style = {{ fontSize : '18px' , fontWeight : '700'}}>{p.designId.custom_price}$</p>
                  <div className="d-flex w-100 justify-content-center gap-md-4 ">
                    <div style = {{ cursor : "pointer"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                         <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                   </svg>
                    </div>
                    <div >
                      <input type="number" min="1" style={{ width : '50px'}}/>
                    </div>
                    <div style = {{ cursor : "pointer"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
  <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8"/>
</svg>
                    </div>
                    <button
                      className="btn btn-danger mb-2"
                      onClick={() => removeCartItem(p._id)}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                       <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                  </svg>
                    </button>
                    </div>
                  </div>
                </div>
              ))}
      </div>
    </div>
  </div>
  </div>
</div>
<div className="card w-100 p-4" style = {{ flexBasis :"50%" , maxHeight : "40vh"}}>
   <h4 >Payment</h4>
   <p style = {{ fontSize : '16px'}}>Price :<p style={{ display : 'inline', marginLeft: "20px"}}>{totalPrice()}</p></p>
   <p style = {{ fontSize : '16px'}}>Discount Price :<p style={{ display : 'inline', marginLeft: "20px"}}></p></p>
   <p style = {{ fontSize : '16px'}}>Total Price :<p style={{ display : 'inline', marginLeft: "20px"}}>{totalPrice()}</p></p>
 
   {auth?.user?.address ? (
                <div className="mb-3">
                  <h4>Current Address</h4>
                  <h5>{auth?.user?.address}</h5>
                  <button
                    className="btn btn-outline-warning"
                    onClick={() => navigate("/dashboard/user/profile")}
                  >
                    Update Address
                  </button>
                </div>
              ) : (
                <div className="mb-3">
                  {auth?.token ? (
                    <button
                      className="btn btn-outline-warning"
                      onClick={() => navigate("/dashboard/user/profile")}
                    >
                      Update Address
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-warning"
                      onClick={() => navigate("/login", { state: "/cart" })}
                    >
                      Please Login to checkout
                    </button>
                  )}
                </div>
              )}
</div>
          {/* <div className="row">
            <div className="col-lg-7 col-md-12 p-0 m-0">
              {[...cart, ...cartData]?.map((p) => (
                <div className="row card flex-row mb-3" key={p._id}>
                  <div className="col-md-4 col-6">
                    <img
                      src={`${p.designId.custom_image}`}
                      className="card-img-top"
                      alt={p.name}
                      width="100%"
                      height="130px"
                    />
                  </div>
                  <div className="col-md-4 col-6">
                    <p>{p.designId.globals.catName}</p>
                    <p>{p.designId.materials.body}</p>
                    <p>Price: {p.designId.custom_price}$</p>
                  </div>
                  <div className="col-md-4 col-12 mt-3 mt-md-0 d-flex flex-column">
                    <button
                      className="btn btn-danger mb-2"
                      onClick={() => removeCartItem(p._id)}
                    >
                      Remove
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => navigate(`/Design/${p.designId._id}`)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-lg-5 col-md-12 cart-summary">
              <h2>Cart Summary</h2>
              <p>Total | Checkout | Payment</p>
              <hr />
              <h4>Total: {totalPrice()}</h4>
              {auth?.user?.address ? (
                <div className="mb-3">
                  <h4>Current Address</h4>
                  <h5>{auth?.user?.address}</h5>
                  <button
                    className="btn btn-outline-warning"
                    onClick={() => navigate("/dashboard/user/profile")}
                  >
                    Update Address
                  </button>
                </div>
              ) : (
                <div className="mb-3">
                  {auth?.token ? (
                    <button
                      className="btn btn-outline-warning"
                      onClick={() => navigate("/dashboard/user/profile")}
                    >
                      Update Address
                    </button>
                  ) : (
                    <button
                      className="btn btn-outline-warning"
                      onClick={() => navigate("/login", { state: "/cart" })}
                    >
                      Please Login to checkout
                    </button>
                  )}
                </div>
              )}
              <div className="mt-2">
                {!clientToken || !auth?.token || !cartData?.length ? (
                  ""
                ) : (
                  <>
                    <DropIn
                      options={{
                        authorization: clientToken,
                        paypal: {
                          flow: "vault",
                        },
                      }}
                      onInstance={(instance) => setInstance(instance)}
                    />
                    <button
                      className="btn btn-primary"
                      onClick={handlePayment}
                      disabled={loading || !instance || !auth?.user?.address}
                    >
                      {loading ? "Processing ...." : "Make Payment"}
                    </button>
                  </>
                )}
              </div>
            </div>
          </div> */}
        </div> 
      </div>
    </Layout>
  );
};

export default CartPage;
